<style>
.pac-container {
    z-index: 10000 !important;
}
</style>

<template>
    <input
        type="text"
        ref="autocomplete"
        :value="value"
        :required="require_notecard_address"
        autocomplete="off"
    />
</template>

<script>
export default {
    props: { value: String, require_notecard_address: Boolean },
    mounted: function () {
        let self = this;

        this.autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete,
            {
                types: ["premise", "subpremise", "street_address"],
                componentRestrictions: { country: "us" },
            }
        );

        document.getElementById('address-line1').addEventListener('input', function() {
            if (!this.value) {
                self.$emit('changed', {});
            }
        });

        this.autocomplete.addListener('place_changed', () => {
            let place = this.autocomplete.getPlace();
            let ac = place.address_components;

            if(!ac) {
                return;
            }

            ac.push({
                long_name: place.formatted_address,
                short_name: place.formatted_address,
                types: ["formatted_address"],
            });

            self.place_changed(ac);
        });
    },

    methods: {
        // Trigger event when place changed
        place_changed: function (components) {
            let place = this.name_address_components(components);

            this.$emit("changed", place);
        },

        // Convert Google place to a more user-friendly object
        name_address_components: function (address_components) {
            let final_object = {};

            $(address_components).each(function (component_index, object) {
                final_object[object.types[0]] = {
                    long_name: object.long_name,
                    short_name: object.short_name,
                };
            });

            return final_object;
        },
    },
};
</script>
